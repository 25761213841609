import React, { useEffect, useState, Suspense } from "react";
import Box from "@mui/material/Box";
import { useTheme, alpha } from "@mui/material/styles";

import Main from "layouts/Main";
import Container from "components/Container";
import { Reviews } from "./components";
import StarIcon from "@mui/icons-material/Star";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";

import People from "./People";

import WithDarkBackgroundAndSimpleDescriptionBox from "./components/WithDarkBackgroundAndSimpleDescriptionBox";
import WhyYnflow from "./components/WhyYnflow";
import { Helmet } from "react-helmet";

import { Work } from "views/Startup/components";

import { ProgressBar } from "@nadfri/react-scroll-progress-bar";
import SellGlobaly from "./components/SellGlobaly";
import Showcase3d from "Showcase3d";
import PhoneMockup from "PhoneMockup";
import { Solutions } from "views/Enterprise/components";
import { HorizontallyAlignedBlogCardWithShapedImage } from "blocks/blog";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { Locations } from "views/Coworking/components";
import { Customization } from "views/DesktopApp/components";
import {
  CaseStudy1,
  CaseStudy2,
  CaseStudy3,
} from "views/DesignCompany/components";
import CaseStudy4 from "views/DesignCompany/components/CaseStudy4";
import { Goby } from "views/Agency/components";
import Hero from "blocks/heroes/HeroWithLogoGridAndDesktopScreenshot/HeroWithLogoGridAndDesktopScreenshot";
import { Services } from "views/WebBasic/components";
import AttractCustomers from "AttractCustomers";

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

// export const GoogleTranslate = ({ mobile }) => {
//   useEffect(() => {
//     if (window.document.scrollingElement.hasAttribute("style")) {
//       window.document.scrollingElement.setAttribute("style", "");
//     }
//     $("document").ready(function () {
//       // RESTYLE THE DROPDOWN MENU
//       $("#google_translate_element").on("click", function () {
//         // Change font family and color
//         $("iframe")
//           .contents()
//           .find(
//             ".goog-te-menu2-item div, .goog-te-menu2-item:link div, .goog-te-menu2-item:visited div, .goog-te-menu2-item:active div, .goog-te-menu2 *"
//           )
//           .css({
//             color: "#544F4B",
//             "font-family": "Montserrat, sans-serif",
//             width: "100%",
//           });
//         // Change menu's padding
//         $("iframe")
//           .contents()
//           .find(".goog-te-menu2-item-selected")
//           .css("display", "none");

//         // Change menu's padding
//         $("iframe").contents().find(".goog-te-menu2").css("padding", "0px");

//         // Change the padding of the languages
//         $("iframe")
//           .contents()
//           .find(".goog-te-menu2-item div")
//           .css("padding", "20px");

//         // Change the width of the languages
//         $("iframe").contents().find(".goog-te-menu2-item").css("width", "100%");
//         $("iframe").contents().find("td").css("width", "100%");

//         // Change hover effects
//         $("iframe")
//           .contents()
//           .find(".goog-te-menu2-item div")
//           .hover(
//             function () {
//               $(this)
//                 .css("background-color", "#FFCB00")
//                 .find("span.text")
//                 .css("color", "#FFCB00");
//             },
//             function () {
//               $(this)
//                 .css("background-color", "#FFCB00")
//                 .find("span.text")
//                 .css("color", "#FFCB00");
//             }
//           );

//         // Change Google's default blue border
//         $("iframe").contents().find(".goog-te-menu2").css("border", "none");

//         // Change the iframe's box shadow
//         $(".goog-te-menu-frame").css(
//           "box-shadow",
//           "0 0px 0px 0px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3)"
//         );

//         // Change the iframe's size and position?
//         $(".goog-te-menu-frame").css({
//           height: "100%",
//           width: "100%",
//           top: "0px",
//         });
//         // Change iframes's size
//         $("iframe").contents().find(".goog-te-menu2").css({
//           height: "100%",
//           width: "100%",
//         });
//       });
//     });
//   });

//   return (
//     <>
//       <Helmet>

//     <script type="text/javascript"
//    src={"https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"}>
// </script>
// <script type="text/javascript">
//   {`

//   function setCookie(key, value, expiry) {
//   var expires = new Date();
//   expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
//   document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
// };
// function googleTranslateElementInit() {
//   document.cookie = 'googtrans'+'=; Max-Age=-99999999;';
//   setCookie('googtrans', "/de/"+navigator.language,1);
//    new window.google.translate.TranslateElement(
//        {pageLanguage: 'de', includedLanguages: 'en,de,zh-CN,es,fr,it,pt,ru,ja,ar,ka', layout: google.translate.TranslateElement.InlineLayout.SIMPLE},
//        'google_translate_element'
//    );
// }
// `}
// </script>
// </Helmet>
//     </>
//   );
// };

const faqmock = [
  {
    title: "Muss ich 3D-Modelle erstellen lassen?",
    subtitle:
      "Wir kümmern uns um Ihren Konfigurator von Anfang bis zum Ende. Wenn Sie schon 3D-Modelle haben, bereiten wir diese für das Internet auf oder erweitern diese. Besitzen Sie keine 3D-Modelle, dann erstellen wir diese für Sie.",
  },
  {
    title: "Wie lange dauert die Entwicklung?",
    subtitle:
      "Da wir die Programmierung und das Erstellen der 3D-Modelle vollständig selber leisten und wir uns auf 3D-Konfiguratoren spezialisiert haben, können Sie mit Projektlaufzeiten von durchschnittlich 8-10 Wochen rechen. Wir machen Fixpreise. ",
  },
  {
    title: "Mit welchen Kosten muss ich rechnen?",
    subtitle:
      "Die Einrichtungskosten hängen einzig mit dem Aufwand zusammen. Der Aufwand lässt sich häufig durch eine kluge Gestaltung reduzieren. Sprechen Sie uns an und erhalten Sie ein Angebot.",
  },
  {
    title: "Wo kann ich mehr erfahren?",
    subtitle:
      "Sprechen Sie uns gerne unter martin@configora.com an oder buchen Sie einen kostenlosen Demo-Termin mit uns. Schauen Sie sich auch gerne unsere Demos an.",
  },
  {
    title: "Ist Configora ein langfristiger Partner?",
    subtitle:
      "Wir stehen schon im ersten Gespräch als kompetenter Ansprechpartner zum Thema 3D-Konfigurator zur Seite. In einer Zusammenarbeit achten wir stets darauf Ihre Ressourcen zu schonen und konzentriert Fortschritte zu erzielen. ",
  },
  {
    title: "Warum sollte ich gerade mit Ynflow zusammenarbeiten?",
    subtitle:
      "Wir sind als Familienunternehmen auf 3D-Konfiguratoren und deren Betrieb spezialisiert. Außerdem entwickeln wir 3D-Modelle und die gesamte Software selbst. Mit uns haben Sie einen Partner gefunden, der Sie im Bereich 3D vollumfänglich unterstützen kann.",
  },
];

// const mock = [
//   {
//     title: "Ohne konfiguratorgetriebenen Vertrieb ",
//     subtitle:
//       "3D-Rundgang für Ihr Produkt. Erklären Sie die wichtigsten Features.",
//     price: { monthly: "$22", annual: "1.500 €" },
//     features: [
//       "Kunden verstehen Produkte nicht, und es kommen immer wieder einfache Rückfragen ohne echtes Kaufinteressen. Gute potentielle Kunden treten nicht in Kontakt und gehen direkt zu Deiner Konkurrenz.",
//       "Es ist mühsam ein Angebot zu erstellen und es gibt häufig zeitaufwändige Missverständnisse. Du verlierst schnell den Überblick und machst dadurch teure Fehler.",
//       "Du hast keine Ahnung, wo der Vertrieb grade steht, weißt nicht was Kunden wollen und wie Du Dein Unternehmen gezielt weiterentwickeln kannst.",
//       "Du gibst viel Geld für den Vertrieb aus, aber die meisten Maßnahmen sind nicht wirksam.",
//     ],
//     isHighlighted: false,
//   },
//   {
//     title: "Mit konfiguratorgetriebenem Vertrieb",
//     subtitle: "Lassen Sie Kunden Ihr Produkt individualisieren.",
//     price: { monthly: "$44", annual: "4.000 €" },
//     features: [
//       "Dein 3D-Produktkonfigurator zieht Kunden an und und wird gerne geteilt. Kunden treten mit bereits viel Vorwissen in Kontakt mit Dir.",
//       "Du kannst in kürzester Zeit bequem ein Angebot zusammenklicken, wenn es der Kunde nicht bereits selbst getan hat. Die Visualisierung zeigt klar, worum es geht.",
//       "Du hast einen systematischen, digitalen Vertriebprozess. Du siehst klar, wie effektiv welche Maßnahmen sind und kannst Dein Unternehmen anhand dessen kontinuierlich verbessern.",
//       "Du hast ein starkes Effizienztool und kannst kostengünstig und global vertreiben.",
//     ],
//     isHighlighted: true,
//   },
//   // {
//   //   title: "Configurator",
//   //   subtitle: "Volle Konfiguration von Produkten.",
//   //   price: { monthly: "$77", annual: "7.000 €" },
//   //   features: [
//   //     "alles aus Explainer und Personalizer",
//   //     "komplexe Produktserien abbilden",
//   //     "modulare Bauweise abbilden",
//   //     "Sales-Dashboard",
//   //     "automatisierte Angebotserstellung",
//   //     "für Online-Ads optimiert",
//   //   ],
//   //   isHighlighted: false,
//   // },
// ];

const menuConfiguration = {
  menuConfiguration: [
    {
      inputType: "color",
      title: "Shell Color",
      choices: [
        { rgb: "#ffcb00", title: "Sun Yellow" },
        { rgb: "#007fff", title: "Ocean Azur" },
        { rgb: "#99C68E", title: "Frog Green" },
      ],
      addNullOption: false,
      currentValue: "#ffcb00",
    },
  ],
  addColorPicker: false,
};

const Showcase3dLazy = React.lazy(() => import("../../Showcase3d"));
const PhoneMockupLazy = React.lazy(() => import("../../PhoneMockup"));

const CloudHosting = () => {
  const [configuration, setConfiguration] = useState(menuConfiguration);
  const theme = useTheme();
  const [animationProgress, setAnimationProgress] = useState(28);
  // useEffect(() => {
  //   const container = document.querySelector(".wireframe-inner-container");
  //   document
  //     .querySelector(".wireframe-slider")
  //     .addEventListener("input", (e) => {
  //       container.style.setProperty("--position", `${e.target.value}%`);
  //     });
  // }, []);
  const [viewPortEntered, setViewPortEntered] = useState(false);

  useEffect(() => {
    const jarallaxInit = async () => {
      const jarallaxElems = document.querySelectorAll(".jarallax");
      if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
        return;
      }

      const { jarallax } = await import("jarallax");
      jarallax(jarallaxElems, { speed: 0.2 });
    };

    jarallaxInit();
  });

  const styles = (bgImage) => ({
    position: "absolute",
    objectFit: "cover",
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    fontFamily: "object-fit: cover;",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundImage: `url(${bgImage})`,
    backgroundAttachment: "fixed",
    filter: "none",
  });

  // const setViewPortVisibility = (isVisible) => {
  //   if (viewPortEntered) {
  //     return;
  //   }

  //   setViewPortEntered(isVisible);
  // };
  // const updateAnimation = () => {
  //   console.log("updateing AP", animationProgress);
  //   setAnimationProgress((activePoint) => {
  //     console.log(activePoint);
  //     return activePoint + 1;
  //   });
  // };
  // const updateAnimationDown = () => {
  //   console.log("updateing AP", animationProgress);
  //   setAnimationProgress((activePoint) => {
  //     console.log(activePoint);
  //     return activePoint - 1;
  //   });
  // };
  // useEffect(()=>{console.log("AP is ",animationProgress); if(animationProgress > 70){
  //   const cvs = document.querySelector("#bike-canvas");
  //   console.log("get bike canvas",cvs)

  // }},[animationProgress])
  //   useEffect(() => {
  //     const cvs = document.querySelector("#bike-canvas");
  //     console.log("get bike canvas",cvs)
  //     cvs
  //       .addEventListener("wheel", (e) => {
  //         //e.preventDefault();
  //         if (e.deltaY < 0)
  //         {
  //           setAnimationProgress(window.scrollY)
  //           updateAnimationDown()
  //         }
  //         else if (e.deltaY > 0)
  //         {
  //          console.log('scrolling down');

  //          updateAnimation()
  //         }
  //         // e.preventDefault();
  //       });
  //   }, []);
  return (
    <>
      <Helmet>
        <meta property="og:image" content="/android-chrome-512x512.png" />
        <meta
          name="description"
          content="Der smarte 3D-Konfigurator für Hersteller Websites. PDFs und Anfrageformulare waren gestern. Hole Deine Kunden interaktiv ab und überzeuge Sie sofort!"
        />
        <title>Configora | Werde zum digitalen Marktführer</title>
        <link rel="shortcut icon" href={"/favicon-32x32.png"} />

        <script
          type="module"
          src="https://modelviewer.dev/node_modules/@google/model-viewer/dist/model-viewer.min.js"
        ></script>
      </Helmet>
      <Main sx={{ overflowX: "hidden", scrollBehavior: "smooth" }}>
        <ProgressBar
          color1="#ddd"
          color2="#ffcb00"
          height="2px"
          position="fixed"
        />
        {/* <div style={{width: "auto", marginTop: "150px", marginBottom: "-00px"}}>
          <center>
        <img src={"https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/mock_stdbrks_1.png?alt=media&token=cc1c37f4-0c53-488a-a322-6f567fbf1555"} width={"800px"} height={"auto"}/>
        </center>
        </div> */}

        <Box
          position={"relative"}
          sx={{
            //backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/macbook_mockup_header.png?alt=media&token=6a1d5966-b673-42ef-8b75-2f7f50e1e11e')",
            objectFit: "cover",
            backgroundSize: "cover",
            height: window.innerWidth > 800 ? "auto" : "auto",
            backgroundPosition: "center",
            backgroundColor: "#f5f5f5",
            // backgroundColor: "rgb(250,250,250)",
            paddingTop: window.innerWidth > 800 ? "150px" : "70px",
            pb: 0,
            mb: 0,
            pb: 0,
            //   backgroundColor:
            //     theme.palette.mode === "light"
            //       ? theme.palette.primary.main
            //       : theme.palette.alternate.main,
          }}
        >
          <Container sx={{ m: 0, width: "100vw", height: "auto", mx: "auto" }}>
            <Hero />
          </Container>

          <Container>
            <Services />
          </Container>

          {/* {window.innerWidth > 800 && (
            <Box
              component={"svg"}
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1921 273"
              sx={{
                position: "absolute",
                width: "100%",
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1,
                height: "35%",
              }}
            >
              <polygon
                fill={theme.palette.background.paper}
                points="0,273 1921,273 1921,0 "
              />
            </Box>
          )} */}
        </Box>
        <Container style={{ marginTop: "120px" }}>
          <center>
            <video
              style={{
                //boxShadow:"rgba(0, 0, 0, 0.15) 0px 2px 8px",
                //borderRadius: "8px",
                // objectFit: "cover",
                zIndex: 200,
                // width: "100%",
                height: "auto",
                width: "min(100%, 1100px)",
              }}
              //controls
              //poster="https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/vsl_poster.png?alt=media&token=c17d861f-b2ad-4e55-a7b5-4302e5af7a95"
              autoPlay
              muted
              loop
            >
              <source
                src={
                  "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/walkginboxes_teaser.webm?alt=media&token=43d5d760-d558-4a80-ba73-57ae2a9fe505"
                }
              ></source>
            </video>
          </center>
          <AttractCustomers />
        </Container>
        <Container sx={{ marginTop: "50px", marginBottom: "0px" }}>
          <Work />
        </Container>
        <Container sx={{ textAlign: "center", m: "auto" }}>
          <Box
            component={"img"}
            sx={{width: "min(100%,700px)"}}
            src={
              "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/iphone_mock_configora.png?alt=media&token=dcde06a5-6b1d-4ce1-bdf4-3e2ac530564a"
            }
          />
        </Container>
        <div style={{ backgroundColor: "#f5f5f5" }}>
          <Container>
            <Typography
              variant={"h4"}
              sx={{
                color: "#222",
                fontWeight: 700,
                textAlign: "center",
                padding: "16px",
                maxWidth: "950px",
                margin: "auto",
                marginTop: "120px",
                padding: "0 0 80px 0",
              }}
            >
              Mit Configora verkaufst Du als Hersteller mehr und schneller
            </Typography>
            <center style={{ marginBottom: "80px" }}>
              <Box
                // sx={{ boxShadow: 1 }}
                component={"img"}
                style={{ borderRadius: "0px", margin: "0 0 30px 0" }}
                sx={{width: "min(100%,700px)"}}
                src={
                  "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/hero_configora.png?alt=media&token=ec481dce-fe1d-4969-bf91-a16753806607"
                }
              />
              <div style={{ width: "min(100%,700px)", textAlign: "left"  }}>
                <i style={{ fontSize: "18px" }}>
                  "Wir haben Configora gegründet, weil wir gesehen haben, dass
                  der Vertrieb bei Premium-Herstellern oftmals sehr komplex
                  ist. Der Austausch von Informationen per Email, PDF oder
                  Telefon ist sehr mühsam und führt zu Missverständnissen.<br/><br/>
                  Preise müssen mühsam kalkuliert werden und Angebote werden oft
                  noch mit manuellen Schritten erstellt. Mit Configora bieten wir die
                  Lösung, die auf direktem Weg zu nachhaltigem Erfolg führt. "{" "}
                  <br />
                  <br />
                  <img
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/unterschrift.PNG?alt=media&token=3e63070d-9fd8-46d0-822b-5cdfb48ca1bb"
                    }
                    width={"190px"}
                  />
                  <br />
                  Martin Dammrath, Gründer von Configora
                </i>
              </div>
            </center>

            <center>
              <Box
                // sx={{ boxShadow: 1 }}
                component={"img"}
                sx={{width: "min(100%,700px)"}}
                style={{ borderRadius: "0px", margin: "100px 0 30px 0" }}
                width={"700px"}
                src={
                  "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/cube_fx_teaser.png?alt=media&token=1a41295f-7ef3-4160-96b5-70a9d8cd433f"
                }
              />
              <div style={{ width: "min(100%,700px)", textAlign: "left" }}>
                <i style={{ fontSize: "18px" }}>
                  "Mit unserem 3D-Planer für die CUBE fx Gartenbüros und -saunen
                  können wir unsere Kunden schnell von unserer Qualität und
                  Professionalität überzeugen.{" "}
                 <br/><br/>
                    Im Vertrieb sparen wir uns und unseren Kunden mühsame
                    Extraschleifen und hunderte Arbeitsstunden jährlich.
                 
                  Die Zusammenarbeit mit Configora war hervorragend. Das Team
                  von Configora hat alle unsere Anforderungen umgesetzt und
                  unsere Erwartungen noch übertroffen." <br />
                  <br />
                  <span
                    style={{
                      fontFamily: '"Oooh Baby", cursive',
                      color: "#355e9d",
                      fontSize: "42px",
                    }}
                  >
                    C. Probst
                  </span>
                  <br />
                  Sales Managerin bei CUBE fx
                </i>
              </div>
            </center>
          </Container>
        </div>
        {/* <Container  sx={{ m: 0, mx: "auto", width: "100%" }}>
            <img width={"100%"} src={"https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/configora_configurator.png?alt=media&token=866739fe-ce8a-4dcd-9138-66d273c754b9"}/>
          </Container> */}
        <Typography
          variant={"h4"}
          mb={"120px"}
          sx={{
            color: "#222",
            fontWeight: 700,
            textAlign: "center",
            padding: "16px",
            maxWidth: "950px",
            margin: "100px auto",
            paddingBottom: "30px",
          }}
        >
          Deine Produkte gekonnt in Szene gesetzt
        </Typography>
        <iframe src={"https://configora.app/embed/rtVTtJ3A3uwX2zITPByc"} frameBorder={0} width={"100%"} height={"100vh"}></iframe>
        <model-viewer
          style={{ height: window.innerWidth > 800 ?"800px" : "450px", background: "white" }}
          //ios-src="some_test.usdz"
          xr-environment
          //environment-image={"/white_bg.jpg"}
          ar-modes="webxr scene-viewer quick-look"
          ar
          alt="Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum"
          src={
            "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/setu_chair-transformed.glb?alt=media&token=d08043f9-d667-4187-aa78-8849fff2fca5"
          }
          tone-mapping="aces_filmic"
          shadow-intensity="1"
          scale="0.2 0.2 0.2"
          camera-controls
          blend-mode="default"
          disable-zoom
          //auto-rotate
          interaction-prompt-threshold="600"
          interaction-prompt-style="wiggle"
          //rotation-per-second="30deg"
          //environment-image="/landscape_sm.hdr"
          touch-action="pan-y"
          exposure={"1"}
          loading="eager"
        >
          <button slot="ar-button" id="ar-button">
            Start AR
          </button>
          <button id="ar-failure">AR is not tracking!</button>

          <div id="ar-prompt">
            <img src="https://modelviewer.dev/shared-assets/icons/hand.png" />
          </div>
          {/* <effect-composer render-mode="quality" msaa="8">
    <bloom-effect></bloom-effect>
    <color-grade-effect></color-grade-effect>
  </effect-composer> */}
        </model-viewer>
        {/* <iframe
          src={"https://configora.app/embed/rPbMh5d6IRHwVBTjGa1L"}
          width={"100%"}
          height={"900px"}
          frameborder={"0"}
        /> */}

        <Box
          sx={{ mt: window.innerWidth > 800 ? "100px" : "230px", mb: "150px" }}
        >
          <div style={{ backgroundColor: "#ffffff", paddingTop: "120px" }}>
            {/* <Typography
              variant={"h4"}
              m={"0px 0 60px 0"}
              sx={{
                color: "#222",
                fontWeight: 700,
                textAlign: "center",
                padding: "16px",
                maxWidth: "950px",
                margin: "0px auto 0px auto",
              }}
            >
              Diese Hersteller bauen Ihren Erfolg auf Configora
            </Typography> */}
            
            <Container>
              <CaseStudy2 />
            </Container>
            <Container>
              <center style={{ padding: "32px" }}>
                <img
                  style={{
                    width: "min(100%, 350px)",
                    objectFit: "cover",
                    filter: "drop-shadow(1rem 0.35rem 1rem rgba(0, 0, 0, 0.5))",
                  }}
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/walkingboxes_teaser_1.png?alt=media&token=be338b1c-5c04-4de5-ac64-89c94ce06d67"
                  }
                />
              </center>
            </Container>
            <Container>
              <CaseStudy1 />
            </Container>
            <Container>
              <center style={{ padding: "32px" }}>
                <img
                  style={{
                    width: "min(100%,700px)",
                    objectFit: "cover",
                    filter: "drop-shadow(1rem 0.35rem 1rem rgba(0, 0, 0, 0.5))",
                  }}
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/cubefx_macbook_teaser.png?alt=media&token=e19d2afd-889f-402d-9685-add1724dff68"
                  }
                />
              </center>
            </Container>
            <Container>
              <CaseStudy3 />
            </Container>
            <Container>
              <center style={{ padding: "32px" }}>
                <img
                  style={{
                    width: "min(100%,700px)",
                    objectFit: "cover",
                    filter: "drop-shadow(1rem 0.35rem 1rem rgba(0, 0, 0, 0.5))",
                  }}
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/klangschild_teaser_1.png?alt=media&token=ba253da1-e9ca-4ae8-9bb5-60f389b218d7"
                  }
                />
              </center>
            </Container>
            {/* <Container>
            <CaseStudy3/>
          </Container> */}
            {/* <Container>
            <CaseStudy4/>
          </Container> */}
            {/* <HorizontallyAlignedBlogCardWithShapedImage
            {...{
              image:
                "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/Gartensauna_SaunaCUBE_Galerie_oben_4.jpg?alt=media&token=f4d30552-f3f9-4ce2-8684-4f8f7a03640d",
              description:
                "Mit unserem Plugin haben wir den Beratungsprozess für CUBE fx aufgewertet. Kunden verstehen nun schnell, wie sie Ihren Cube individualisieren können. Der Vertriebsprozess ist damit interaktiver und zielorientierter geworden. Zudem profitiert die Außendarstellung des Unternehmens.",
              title:
                "3D-Experience für hochwertige Design-Gartenräume aus Edelstahl",
              label: "Made in Saarland",
              author: {
                name: "CUBE fx GmbH",
                avatar:
                  "https://cube-fx.de/wp-content/uploads/2022/12/CUBEfx_Logo_new.png",
              },
              date: "Projektdauer: 3 Monate",
            }}
          /> */}

            {/* <HorizontallyAlignedBlogCardWithShapedImage
            {...{
              image:
                "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/wb_customer_story.jpg?alt=media&token=ac3874fb-ecd4-45f9-98ab-28cffc63a69d",
              description:
                "Foodtrailer aus Seecontainern mit smartem Design und hochwertigem Ausbau. Dafür steht walkingboxes aus Berlin. Jetzt können Kunden Ihren individuellen Foodtrailer auf der Website konfigurieren und in 3D erleben.",
              label: "Made in Berlin",
              title:
                "Foodtrailer Manufaktur walkingboxes erhält 3D-Showcase für die Vermarktung",
              author: {
                name: "walkingboxes GmbH",
                avatar:
                  "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/walkingboxes-logo-hoch.png?alt=media&token=27786c24-dd13-42ea-a7a8-bd3e57c54959",
              },
              date: "Projektdauer: 2,5 Monate",
            }}
          /> */}

            {/* <HorizontallyAlignedBlogCardWithShapedImage
            {...{
              image:
                "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/klangschild_teaser.png?alt=media&token=ecc65189-c8e0-40de-b8ff-f046f49d769b",
              description:
                "Für die Firma Klangschild, die eine vollständig modulare Akustik-Kabine entwickelt hat, haben wir mit unserem Plugin eine vollständige Online-Vertriebslösung bereitgestellt. Hier können sich Kunden Ihr Klangschild konfigurieren und sofort bestellen.",
              label: "Made in Wiesbaden",
              title:
                "Modulare Room-In-Room Akustiklösung Klangschild in 3D konfigurieren und bestellen",
              author: {
                name: "Klangschild GmbH",
                avatar:
                  "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/klangschild.png?alt=media&token=d1ddf359-b9be-4ad4-a8b4-cf049b5d0c12",
              },
              date: "Projektdauer: 2,5 Monate",
            }}
          /> */}
          </div>
          {/* <HorizontallyAlignedBlogCardWithShapedImage
            {...{
              image:
                "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/gartenkubus_teaser.jpg?alt=media&token=d69ea65d-8667-4eb9-b121-daac1e4de78f",
              description:
                'Den "Wohnraum" von Garten-Kubus können Kunden nun bald schon vor dem Kauf in 3D erleben und sich individuell nach Ihren Wünschen zusammenstellen. So punktet Garten-Kubus bei seinen Kunden mit dem attraktiven Design Ihrer hochwertigen und ökologischen Garten(t)räume.',
              label: "Made in Schondorf am Ammersee",
              title:
                'Interaktives 3D-Erelbnis demonstriert den "Wohnraum" von Garten-Kubus',
              author: {
                name: "Garten-Kubus",
                avatar: "/ALix3WF4dSsrubYu2NBH.png",
              },
              date: "",
            }}
          /> */}
        </Box>
        {/* {window.innerWidth > 800 && (
          <Container
            sx={{
              height: window.innerWidth > 800 ? "800px" : "300px",
              pt: 0,
              width: "100vw",
            }}
          >
            <Suspense fallback={<center>Lade...</center>}>
              <Showcase3dLazy />
            </Suspense>
          </Container>
        )} */}

        {/* <Box
        className={'jarallax'}
        data-jarallax
        data-speed="2.8"
        position={'relative'}
        minHeight={'100vh'}
        display={'flex'}
        alignItems={'center'}
        id="agency__portfolio-item--js-scroll"
      >
        <Box
          className={'jarallax-img'}
          sx={styles(
            'https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/isometric-web-pages-mockup.png?alt=media&token=5dffa169-d32a-4489-9433-d964e2d2f034',
          )}
        />
        <Container>
          <Goby/>
        </Container>
      </Box> */}

        {/* <img style={{width: "100vw", height: "auto"}} src={"https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/macbook_mockup.png?alt=media&token=b88e8244-9979-4058-8acc-8b8664d37820"}/> */}
        {/* <Container>
        <Customization/>
        </Container> */}

        {/* <Box>
          <WithDarkBackgroundAndSimpleDescriptionBox />
          
        </Box> */}

        {/* <Box sx={{ mt: "100px", mb: "150px" }}>
          <Box display={"flex"} justifyContent={"center"}>
            {[1, 2, 3, 4, 5].map((item) => (
              <Box key={item} color={theme.palette.secondary.main}>
                <StarIcon sx={{ fontSize: "18px" }} />
              </Box>
            ))}
          </Box>
          <center>
            <Typography
              variant={"h5"}
              mb={"10px"}
              sx={{
                color: "#222",
                fontWeight: 700,
                textAlign: "center",
                maxWidth: "800px",
                padding: "16px",
              }}
            >
              "Ich kann unseren Kunden direkt mit einem Klick zeigen, wie sich
              unser Cube individualisieren lässt. Vorher musste die Konstruktion
              Renderings erstellen, was mehrere Tage gedauert hat."
              <br />
              <small style={{ fontWeight: "400", fontSize: "13px" }}>
                Christina Probst, Sales Managerin bei CUBE fx
              </small>
            </Typography>
            <video
              style={{
                // objectFit: "cover",
                // borderRadius: "8px",
                zIndex: 200,
                height: window.innerWidth > 800 ? "300px" : "200px",
                width: window.innerWidth > 800 ? "550px" : "300px",
              }}
              controls
              poster="/testimonial_cube_poster.png"
              // autoPlay
              // muted
              // loop
            >
              <source
                src={
                  "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/Testimonial_Ynflow_Cube_fx.mp4?alt=media&token=8b759a70-6abb-4a73-a9cd-dc573ca68746"
                }
              ></source>
            </video>
          </center>
        </Box> */}
      
        {/* <Container>
          <GenerationNow />
        </Container> */}

        {/* <Box
                  component={'video'}
                //   src={
                //     theme.palette.mode === 'light'
                //       ? 'https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/pexels-miguel-%C3%A1-padri%C3%B1%C3%A1n-16094471%20(Original).mp4?alt=media&token=39b5005f-00be-4b55-8cf8-31a456d49005'
                //       : 'https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/pexels-miguel-%C3%A1-padri%C3%B1%C3%A1n-16094471%20(Original).mp4?alt=media&token=39b5005f-00be-4b55-8cf8-31a456d49005'
                //   }
                //   loading={'lazy'}
                  width={1}
                //   preload='auto'
                  autoPlay
                  loop
                  muted
                //controls
                   height={1}
                  //max-height={"50vh"}
                  sx={{
                    //filter: "drop-shadow(0.8rem 0.8rem 0.75rem #222)",
                    // zIndex: 999999999999,
                    //display: "inline-block",
                    filter: "brightness(1.5) invert(0)",
                     objectFit: "cover",
                    
                    // borderRadius: '2.5rem',
                    // filter:
                    //   theme.palette.mode === 'dark'
                    //     ? 'brightness(0.7)'
                    //     : 'none',
                  }}
                >  <source src="https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/klangschild_demo.mp4?alt=media&token=23a4ff7b-774f-4d82-bd34-2856a041970d" type="video/mp4"></source>
                </Box> */}
        {/* <Suspense fallback={<center>Lade...</center>}>
          <PhoneMockupLazy />
        </Suspense> */}

        {/* <Box sx={{ margin: "100px 0px" }}>
          <Container>
            <Typography
              color="black"
              sx={{
                textAlign: window.innerWidth > 800 ? "auto" : "center",
                fontWeight: 700,
                fontSize: window.innerWidth > 800 ? "50px" : "26px",
              }}
            >
              Setzt Du die Zukunft Deines Unternehmens noch mit{" "}
              <Typography
                color={"black"}
                component={"span"}
                variant={"inherit"}
                sx={{
                  background: `linear-gradient(180deg, transparent 82%, ${alpha(
                    theme.palette.secondary.main,
                    1
                  )} 0%)`,
                }}
              >
                {" "}
                nervigen Formularen, zeitfressendem Email-Ping-Pong oder einem
                PDF-Katalog
              </Typography>{" "}
              auf Spiel?
            </Typography>
            <Typography variant={"h6"} mt={6}>
              Vertrieb und Marketing sind KERNKOMPETENZEN Deines Unternehmens.
              Ein gutes Produkt alleine reicht nicht mehr aus. Der Markt ist
              umkämpfter denn je und digitale Unternehmen übernehmen mehr und
              mehr Marktanteile. <br />
              <br />
              Sieh der Realität ins Auge und beginne jetzt mit der
              Implementierung eines wirksamen, konfiguratorgetriebenen
              Vertriebs, damit Du der Konkurrenz einen Schritt voraus bist und
              so die Zukunft Deines Unternehmens sicherst.
            </Typography>
          </Container>
        </Box> */}
        {/* <Box sx={{margin:0, padding:0}}><Container sx={{marginTop: "-200px"}}>
  <ContactCard/>
  </Container></Box> */}
        {/* <Typography variant={'h3'} color={"#222"} mb={"100px"} mt={"150px"} gutterBottom sx={{textAlign: "center", fontWeight: 700 }}>
              Warum das 3D-Plugin von Ynflow?
              </Typography>
        <Box sx={{ background: "white" }}>
          <Container paddingTop={"0 !important"}>
            <About />
          </Container>
        </Box> */}
        {/* <Box sx={{ margin: "50vh 0px 50vh 0" }}>
          <Container data-aos={"fade-in"}>
            <Typography
              color="#222"
              sx={{
                textAlign: window.innerWidth > 800 ? "center" : "center",
                fontWeight: 700,
                letterSpacing: "-2px",
              }}
              variant={"h1"}
            >
              Erreiche bis zu 94 % mehr Verkäufe mit einem 3D-Konfigurator.
            </Typography>
            <Typography variant={"body1"} sx={{ float: "right" }}>
              * Quelle:{" "}
              <Link
                style={{ color: "black" }}
                href={
                  "https://www.shopify.com/enterprise/augmented-reality-ecommerce-shopping"
                }
                target={"_blank"}
              >
                Shopify
              </Link>
            </Typography>
          </Container>
        </Box> */}
        {/* <Box
        // sx={{ margin: "50vh 0px 50vh 0" }}
        >
          <Container data-aos={"fade-in"}>
            <center>
              <Typography
                variant={"h3"}
                mb={"20px"}
                sx={{
                  color: "#222",
                  fontWeight: 700,
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                Unser eBook zum Thema "Digital Verkaufen" als Premium
                Hersteller.
              </Typography>
              <p style={{ marginBottom: "60px", maxWidth: "700px" }}>
                Bald ist unser eBook für Premium-Hersteller kostenfrei zum
                Dowload erhätlich. Du kannst es aber jetzt schon vorbestellen!
              </p>
              <img
                src={
                  "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/ebook_ynflow_small.png?alt=media&token=bc4caf36-6659-40cd-9e0b-1fd2cc2ad96d"
                }
                width={"200px"}
              ></img>

              <p
                style={{
                  marginBottom: "60px",
                  marginTop: "30px",
                  maxWidth: "700px",
                }}
              >
                In unserem eBook erklären wir Dir, wie Du eine erfolgreiche
                Digitalisierungsstrategie als Hersteller entwickeln kannst und
                welche wichtige Funktion unser 3D-Konfigurator dabei für Dich spielen
                kann.
              </p>
              <Box sx={{ textAlign: "center", mt: 5 }}>
                <Button
                  variant="contained"
                  color="primary"
                  component="a"
                  onClick={() =>
                    window.open(
                      "https://nethunt.com/service/automation/forms/6628baef170d690008c13fa7",
                      "_blank"
                    )
                  }
                  className={"butn butn__new"}
                  size="large"
                  sx={{
                    position: "relative",
                    width: "300px",
                    mt: "20px",
                    margin: "auto",
                    position: "relative",
                    zIndex: 10000,
                    "&:hover": { background: "#ffcb00" },
                    color: "black",
                    background: theme.palette.primary.main,
                    boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 8px`,
                    borderRadius: "28px",
                    fontWeight: "500",
                  }}
                  endIcon={<DownloadOutlinedIcon />}
                >
                  Kostenloser Download
                </Button>
                <br />
                <p style={{ marginTop: "15px" }}>
                  There is an English version, too 🇬🇧 🇺🇸 🇦🇺
                </p>
              </Box>
            </center>
          </Container>
        </Box> */}
        {/* <Canvas id={"bike-canvas"} style={{width: "100vw", height: "100vh"}}>
          <ScrollControls pages={1} damping={0.5}>
<BikeAnimation animationProgress={animationProgress}/>
</ScrollControls>
</Canvas> */}
        {/* <img width={"100%"} height={"1000px"} style={{marginBottom: "-100vh"}} src={"https://images.pexels.com/photos/6908499/pexels-photo-6908499.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}></img> */}
        {/* <Grid container>
       
          <Grid sx={{ height: "700px", width: "50%" }}>
            <model-viewer
              style={{ width: "100%", height: "100%" }}
              id={"model-view-lp"}
              src="https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/sauna_ar.glb?alt=media&token=7a51ba55-be5b-4b76-9b0c-1132d7394b7f"
              ar
              ar-scale="fixed"
              camera-controls
              tone-mapping="commerce"
              disable-zoom
              touch-action="pan-y"
               x
              alt="3D Sauna"
              shadow-intensity="2"
              // skybox-image="/sauna_bg.hdr"
              //  skybox-height="5m"
              // skybox-height="1.5m"
              environment-image="neutral"
              max-camera-orbit="auto 90deg auto"
              xr-environment
            >
              <button slot="ar-button" id="ar-button">
                View in your space
              </button>
              <button id="ar-failure">AR is not tracking!</button>

              <div id="ar-prompt">
                <img src="https://modelviewer.dev/shared-assets/icons/hand.png" />
              </div>
            </model-viewer>
          </Grid>
        </Grid> */}

        {/* <Box
          sx={{
            marginTop: "-200px",
            paddingTop: 0,
            display: window.innerWidth > 800 ? "block" : "none",
          }}
        >
          <Container>
            <main className="wireframe-container">
              <div className="wireframe-inner-container">
                <div className="wireframe-image-container">
                  <div className={"wireframe-image-before"}>
                    <img
                      className=" wireframe-slider-image"
                      src="https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/bike_orig_text.png?alt=media&token=47e0d65a-50bf-4bcf-8f93-4cf4fa15e37f"
                      alt="color photo"
                    />
                  </div>
                  <img
                    className="wireframe-image-after wireframe-slider-image"
                    src="https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/bike.png?alt=media&token=f8c62e31-8ffc-4d6c-b8e3-38761809b1b9&_gl=1*1udgmpu*_ga*MTMwODY2MDE0NS4xNjk3NjUxOTUx*_ga_CW55HF8NVT*MTY5ODA5MzQyNi43LjEuMTY5ODA5MzQ0Mi40NC4wLjA."
                    alt="black and white"
                  />
                </div>

                <input
                  type="range"
                  min={0}
                  max={100}
                  value={10}
                  aria-label="Percentage of before photo shown"
                  className="wireframe-slider"
                  onMouseDown={() => {
                    try {
                      let el = document.getElementById("wrf-btn");
                      el.classList.remove(["animate__flash"]);
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  // onMouseUp={()=>{
                  //   try{
                  //     let el = document.getElementById("wrf-btn")
                  //     el.classList.add(["animate__flash"])
                  //   }catch(e){
                  //     console.log(e)
                  //   }
                  // }}
                />
                <div className="wireframe-slider-line" aria-hidden="true"></div>
                <div
                  id={"wrf-btn"}
                  className="wireframe-slider-button animate__animated animate__flash animate__slow animate__infinite"
                  aria-hidden="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    viewBox="0 0 256 256"
                  >
                    <rect width="256" height="256" fill="none"></rect>
                    <line
                      x1="128"
                      y1="40"
                      x2="128"
                      y2="216"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="16"
                    ></line>
                    <line
                      x1="96"
                      y1="128"
                      x2="16"
                      y2="128"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="16"
                    ></line>
                    <polyline
                      points="48 160 16 128 48 96"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="16"
                    ></polyline>
                    <line
                      x1="160"
                      y1="128"
                      x2="240"
                      y2="128"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="16"
                    ></line>
                    <polyline
                      points="208 96 240 128 208 160"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="16"
                    ></polyline>
                  </svg>
                </div>
              </div>
            </main>
          </Container>
        </Box> */}
        {/* <Box sx={{ margin: "70px 0" }}>
          <Box
            component={"svg"}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
            sx={{
              marginBottom: "-9px",
              position: "relative",
              width: "100%",
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 2,
              height: "35%",
            }}
          >
            <polygon fill={"#ffcb00"} points="0,273 1921,273 1921,0 " />
          </Box>
          <Box sx={{ background: "#ffcb00" }}>
            <Products />
          </Box>
          <Box
            component={"svg"}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
            sx={{
              marginTop: "-1px",
              transform: "rotate(-180deg)",
              position: "relative",
              width: "100%",
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 2,
              height: "35%",
            }}
          >
            <polygon fill={"#ffcb00"} points="0,273 1921,273 1921,0 " />
          </Box>
        </Box> */}
        {/* <Box sx={{ background: "#fff" }}>
          <Container paddingTop={"0 !important"} sx={{ marginTop: "100px" }}>
            <SellGlobaly />
          </Container>
        </Box> */}

        {/* <Box sx={{ margin: "0px 0 200px 0" }}>
          <Container>
            <Typography
              color="text.primary"
              sx={{
                textAlign: window.innerWidth > 800 ? "auto" : "center",
                fontWeight: 700,
                fontSize: window.innerWidth > 800 ? "50px" : "26px",
              }}
            >
              <Typography
                color={"primary"}
                component={"span"}
                variant={"inherit"}
                sx={{
                  background: `linear-gradient(180deg, transparent 82%, ${alpha(
                    theme.palette.secondary.main,
                    0.3
                  )} 0%)`,
                }}
              >
                Mit IT-Projekten überfordert?
              </Typography>{" "}
              Ihren 3D-Sales-Booster entwickeln wir für Sie von{" "}
              <Typography
                color={"primary"}
                component={"span"}
                variant={"inherit"}
                sx={{
                  background: `linear-gradient(180deg, transparent 82%, ${alpha(
                    theme.palette.secondary.main,
                    0.3
                  )} 0%)`,
                }}
              >
                {" "}
                A bis Z.
              </Typography>{" "}
            </Typography>
          </Container>
        </Box> */}
         <Box sx={{ margin: "70px auto" }}>
          {" "}
          <WhyYnflow />
        </Box>
        <Container sx={{ margin: "0px auto 0 auto" }}>
          <People />
        </Container>

        <Box sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            component="a"
            onClick={() =>
              window.open(
                "https://calendly.com/configora/configora-demo",
                "_blank"
              )
            }
            className={"butn butn__new"}
            size="large"
            sx={{
              position: "relative",
              width: "300px",
              mt: "20px",
              margin: "auto",
              position: "relative",
              zIndex: 10000,
              "&:hover": { background: "#ffcb00" },
              color: "black",
              background: theme.palette.primary.main,
              boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 8px`,
              borderRadius: "28px",
              fontWeight: "500",
            }}
            endIcon={
              <Box
                component={"svg"}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={24}
                height={24}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </Box>
            }
          >
            Kostenlose Demo
          </Button>
        </Box>
        <Container>
          <Locations />
        </Container>
       

        {/* <Box sx={{ margin: "70px auto" }}>
          <Container>
            <Grid container spacing={4}>
              {mock.map((item, i) => (
                <Grid item xs={12} md={6} key={i}>
                  <Box
                    component={Card}
                    height={1}
                    display={"flex"}
                    flexDirection={"column"}
                    variant={"outlined"}
                  >
                    <CardContent
                      sx={{
                        padding: 4,
                      }}
                    >
                      <Box marginBottom={2}>
                        <Typography
                          variant={"h4"}
                          fontWeight={600}
                          gutterBottom
                          sx={{
                            color: i == 0 ? "#FF0041" : "green",
                            fontSize: window.innerWidth < 800 ? "20px" : "auto",
                          }}
                        >
                          {item.title}
                        </Typography>
                       
                      </Box>
                      <Box
                        display={"flex"}
                        alignItems={"baseline"}
                        marginBottom={2}
                      >
                        <Typography
                          variant={"subtitle1"}
                          color={"text.secondary"}
                          fontWeight={700}
                        ></Typography>
                      </Box>
                      <Grid container spacing={1}>
                        {item.features.map((feature, j) => (
                          <Grid item xs={12} key={j}>
                            <Box
                              component={ListItem}
                              disableGutters
                              width={"auto"}
                              padding={0}
                            >
                              <Box
                                component={ListItemAvatar}
                                minWidth={"auto !important"}
                                marginRight={2}
                              >
                                <Box
                                  component={Avatar}
                                  bgcolor={i == 0 ? "#FF0041" : "green"}
                                  width={20}
                                  height={20}
                                >
                                  {i == 0 ? (
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        display: "inline-bloc",
                                        marginLeft: "-1px",
                                      }}
                                    >
                                      x
                                    </span>
                                  ) : (
                                    <svg
                                      width={12}
                                      height={12}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  )}
                                </Box>
                              </Box>
                              <ListItemText primary={feature} />
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                    <Box flexGrow={1} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box> */}
        <Container sx={{ my: "0px", mt: "100px" }}>
          <Typography
            variant={"h4"}
            sx={{
              marginBottom: "0px",
              marginTop: "60px",
              textAlign: "center",
              fontSize: "40px",
              fontWeight: "700",
              color: "#222",

            }}
          >
            So startest Du mit uns gemeinsam durch
          </Typography>
          {/* <Process /> */}
        </Container>
        <Container sx={{ maxWidth: "900px", mb: window.innerWdith > 800 ?"150px" : "50px" }}>
          <Solutions />
        </Container>

        <Box
          component={Box}
          sx={{
            margin: "16px",
            // backgroundImage: "url(/undraw_group_video_re_btu7.svg)",
            // backgroundSize: "original",
            boxShadow: null,
            background: "white",
            height: "auto",
            p: 0,
            maxWidth: "1200px",
            mx: "auto",
            //boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 8px`,
          }}
        >
          <Box
            sx={{
              // background: "rgba(255,255,255,0.8)",
              margin: "auto",
              maxWidth: "1200px",
              textAlign: "center",
              // backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/termina_tbilisi.jpg?alt=media&token=4e255261-8639-42e0-b528-e9b8936406c9')",
              // backgroundSize: "cover",
              // backgroundPosition: "center",
              p: 0,
              m: 0,
            }}
            // className={"gradient-background"}
          >
            {/* <div
              style={{ position: "relative", height: "0", maxWidth: "1200px" }}
            >
              <svg preserveAspectRatio="xMidYMid slice" viewBox="10 10 80 80">
                <path
                  fill="rgba(0,0,0,0.02)"
                  class="out-top"
                  d="M37-5C25.1-14.7,5.7-19.1-9.2-10-28.5,1.8-32.7,31.1-19.8,49c15.5,21.5,52.6,22,67.2,2.3C59.4,35,53.7,8.5,37-5Z"
                />
                <path
                  fill="rgba(0,0,0,0.02)"
                  class="in-top"
                  d="M20.6,4.1C11.6,1.5-1.9,2.5-8,11.2-16.3,23.1-8.2,45.6,7.4,50S42.1,38.9,41,24.5C40.2,14.1,29.4,6.6,20.6,4.1Z"
                />
                <path
                  fill="rgba(0,0,0,0.02)"
                  class="out-bottom"
                  d="M105.9,48.6c-12.4-8.2-29.3-4.8-39.4.8-23.4,12.8-37.7,51.9-19.1,74.1s63.9,15.3,76-5.6c7.6-13.3,1.8-31.1-2.3-43.8C117.6,63.3,114.7,54.3,105.9,48.6Z"
                />
                <path
                  fill="rgba(0,0,0,0.02)"
                  class="in-bottom"
                  d="M102,67.1c-9.6-6.1-22-3.1-29.5,2-15.4,10.7-19.6,37.5-7.6,47.8s35.9,3.9,44.5-12.5C115.5,92.6,113.9,74.6,102,67.1Z"
                />
              </svg>
            </div> */}
            {/* <Box component={"div"} sx={{ backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/termina_tbilisi.jpg?alt=media&token=4e255261-8639-42e0-b528-e9b8936406c9')",
              backgroundSize: "cover",height: "500px", backgroundPosition: "top"}}></Box> */}
            <Grid
              container
              sx={{
                zIndex: 100,
                maxWidth: "1200px",
                position: "relative",
                p: 0,
                m: 0,
                width: "100vw",
                height: "100%",
              }}
            >
              <Grid xs={12} md={12}>
                <Avatar
                  sx={{
                    //boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 8px`,
                    width: "350px",
                    background: "white",
                    width: 250,
                    height: 250,
                    marginRight: 2,
                    margin: "auto",
                    mt: window.innerWidth > 800 ? "150px" : "20px",
                    zIndex: 100,
                    borderRadius: 0,
                  }}
                  src={"https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/maria_profile_transparent.png?alt=media&token=2454fc7b-a6a9-44bf-ac1f-b2ebf3857db4"}
                />
              </Grid>
              <Grid xs={12} md={12} sx={{ mt: 5 }}>
                {" "}
                <Reviews />
              </Grid>
              <Typography
                sx={{
                  maxWidth: "500px",
                  color: "text.secondary",
                  color: "#999",
                  margin: "auto",
                  mx: "auto",
                }}
              >
                Lerne uns im kostenlosen Erstgespräch kennen und finde heraus,
                wie Configora Dir helfen kann.
              </Typography>
              <Grid xs={12} md={12}>
                <Box
                  sx={{
                    mb: window.innerWidth > 800 ?"150px":0,
                    textAlign: "center",
                    display: "block",
                    marginTop: "40px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    component="a"
                    // className={"animate__animated animate__headShake animate__slower animate__infinite"}
                    onClick={() =>
                      window.open(
                        "https://calendly.com/configora/configora-demo",
                        "_blank"
                      )
                    }
                    className={"butn butn__new"}
                    size="large"
                    sx={{
                      "&:hover": { background: "#ffcb00" },
                      boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 8px`,
                      fontSize: "16px",
                      position: "relative",
                      width: "350px",
                      maxWidth: "100%",
                      mt: "50px",
                      margin: "auto",
                      position: "relative",
                      zIndex: 10000,
                      fontWeight: "bold",

                      color: "#222",
                      background: theme.palette.primary.main,
                      mt: 7,
                      mb: 6,
                      borderRadius: "28px",
                      fontWeight: "500",
                      boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 8px`,
                    }}
                    endIcon={
                      <Box
                        component={"svg"}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        width={18}
                        height={18}
                        className={
                          "animate__animated animate__headShake animate__slow animate__infinite"
                        }
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </Box>
                    }
                  >
                    Kostenlose Demo
                  </Button>
                  {/* <img src={"https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/explosion.png?alt=media&token=7a902605-6759-4284-9e3f-d80390b7f795"}/>
                   */}
                </Box>
              </Grid>
            </Grid>
            {/* <Box component={"div"} sx={{ backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/termina_tbilisi.jpg?alt=media&token=4e255261-8639-42e0-b528-e9b8936406c9')",
              backgroundSize: "cover",height: "500px", backgroundPosition: "bottom"}}></Box>
          */}
          </Box>
        </Box>
        <Box>
          <Box sx={{ padding: window.innerWidth > 800 ?"90px 0" : "90px 0 30px 0" }}>
            <Typography fontWeight={500} variant={"h4"} align={"center"}>
              Häufig gestellte Fragen
            </Typography>
          </Box>
          <Grid
            container
            spacing={window.innerWidth > 800 ? 2 : 0}
            sx={{
              padding: "0 20px 150px 20px",
              margin: "auto",
              maxWidth: "1300px",
            }}
          >
            {faqmock.map((item, i) => (
              <Grid
                key={i}
                item
                sx={{ marginTop: "15px" }}
                xs={12}
                sm={6}
                md={4}
              >
                <Typography variant={"h6"} fontWeight={500} gutterBottom>
                  {item.title}
                </Typography>
                <Typography color="text.secondary">{item.subtitle}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Main>
    </>
  );
};

export default CloudHosting;
