import React, {
  Suspense,
  lazy,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./style.css";
import {
  Routes as ReactRoutes,
  useParams,
  useNavigate,
} from "react-router-dom";

import { Box } from "@mui/joy";
import { MenuSlider } from "./MenuSlider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
} from "@mui/material/styles";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";

import "animate.css";

import { Button, Card, Typography } from "@mui/material";
import { app } from "index";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { doc, getFirestore } from "firebase/firestore";
import ErrorBoundary from "ErrorBoundary";
import { Toaster, toast } from "sonner";
import { TourProvider, useTour } from "@reactour/tour";
import Mask from "@reactour/mask";
import { Helmet } from "react-helmet";
import useWindowSize from "useWindowSize";

export const THEME = createTheme({
  typography: {
    fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    color: "#888",
    fontWeightLight: 100,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
  },
  palette: {
    primary: {
      main: "rgb(0,0,255)",
    },
    alternate: {
      main: "rgb(255, 0, 0)",
    },
    error: {
      main: "rgb(255,0,0)",
    },
  },
  OutlinedInput: {
    shape: {
      borderRadius: 4,
    },
  },
  button: {
    shape: {
      borderRadius: 4,
    },
  },
  shape: {
    borderRadius: 4,
  },
});

export const primaryColorR = "0";
export const primaryColorG = "72";
export const primaryColorB = "184";
export const primaryColor = `rgb(${primaryColorR},${primaryColorG},${primaryColorB})`;

export const PrimaryColor = () => {
  let { id } = useParams();
  const [parametrization, loading, error] = useDocumentDataOnce(
    doc(getFirestore(app), "parametrizations", id).withConverter(converter)
  );
  if (parametrization) {
    return {
      primaryColor: `rgb(${parametrization.branding.primaryColor.r},${parametrization.branding.primaryColor.g},${parametrization.branding.primaryColor.b})`,
      primaryColorR: parametrization.branding.primaryColor.r,
      primaryColorG: parametrization.branding.primaryColor.g,
      primaryColorB: parametrization.branding.primaryColor.b,
    };
  }
};

export const converter = {
  toFirestore(post) {
    return {
      text: post.text,
    };
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ...data,
    };
  },
};

export const menuConfiguration = [
  {
    inputType: "color",
    title: "Roof color",
    choices: [
      { rgb: "#525151", title: "Slate" },
      { rgb: "#653B8C", title: "Lavender" },
      { rgb: "#36501B", title: "Chatreuse" },
      { rgb: "#AC4520", title: "Orange" },
    ],
    addNullOption: false,
    addColorPicker: true,
    currentValue: "#525151",
    associatedMeshesNames: [
      "ceiling_low_coffee-stand_0",
      "cloth-3_low_coffee-stand_0",
      "parch_low_coffee-stand_0",
    ],
  },
  {
    inputType: "text",
    title: "Text on sign",
    validationRegex: "",
    currentValue: null,
    associatedMeshesNames: ["text-branding-roof"],
    maxLetters: 12,
  },
  {
    inputType: "singleSelect",
    title: "Coffee machine",
    options: [
      {
        title: "La Cimbali M39",
        image: "https://mirmigicoffee.gr/wp-content/uploads/2022/09/2-1.jpg",
      },
      {
        title: "ECM Technika",
        image:
          "https://hommel-kaffeesysteme.de/wp-content/uploads/ecm-technika-v-profi-pid-front.jpg",
      },
    ],
    preselected: null,
    currentValue: null,
    associatedMeshesNames: [],
  },
  {
    inputType: "singleSelect",
    title: "Grinder",
    options: [
      {
        title: "Mahlkönig EK43",
        image:
          "https://www.kaffeekontor.org/out/pictures/master/product/1/ek43.png",
      },
      {
        title: "QuickMill Macina",
        image:
          "https://www.kaffeemaschinen-werkstatt.de/files/content/produkte/kaffeemuehlen/quickmill_macina_caffee.jpg",
      },
    ],
    preselected: null,
    currentValue: null,
    associatedMeshesNames: [],
  },
  {
    inputType: "color",
    title: "Base",
    choices: [
      { rgb: "#fa5", title: "Pine Wood", material: "Pine Wood" },
      {
        rgb: "#555",
        title: "Stainless Steel",
        material: "Cross Brushed Metal",
      },
    ],
    addNullOption: false,
    unselectOnClick: true,
    preselected: null,
    currentValue: null,
    associatedMeshesNames: [],
  },
];

export const ConfigLoadingOverlay = ({
  notAnimated,
  fadeIn,
  showOverlayProp,
}) => {
  const [showOverlay, toggleShowOverlay] = useState(showOverlayProp);
  const [hideOverlay, toggleHideOverlay] = useState(false);
  let { id } = useParams();
  // useEffect(() => {
  //   const timerAnimation = setTimeout(() => {
  //     document.getElementById("overlayBox").classList.add("animate__fadeOut");
  //   }, 3000);
  //   const timer = setTimeout(() => {
  //     toggleShowOverlay(false);
  //   }, 3000);
  // }, []);
  useEffect(() => {
    toggleShowOverlay(showOverlayProp);
    !showOverlayProp &&
      setTimeout(() => {
        toggleHideOverlay(true);
      }, 2000);
  }, [showOverlayProp]);

  if (true) {
    return (
      <Box
        className={
          "animate__animated " 
          // +
          // (!showOverlay && "animate__fadeOut animate__slower")
        }
        id={"overlayBox"}
        sx={{
          background: (window.location.href.indexOf("ALix3WF4dSsrubYu2NBH") > -1 ||  window.location.href.indexOf("nSMcricSRsZydKUphjNV") > -1 || window.location.href.indexOf("3pkpnMYKfM4smxhiJGV2") > -1) ? "#f7f6f0" : "rgba(255,255,255,1)",
          zIndex: 9999999999,
          width: "100vw",
          height: "100vh",
          display: !hideOverlay ? "flex" : "none",
          position: "fixed",
          cursor: "wait",
        }}
      >
        <Box sx={{ margin: "auto", userSelect: "none" }}>
          {/* <div class="ring">
  <i 
  style={{"--clr":"#222"}}
  ></i>
  <i 
  style={{"--clr":"#444"}}
  ></i>
  <i 
  style={{"--clr":"#666"}}
  ></i> */}

          <Box
            sx={{ userSelect: "none" }}
            className={
              "animate__animated "
              //+
              // (!notAnimated
              //   ? "animate__pulse"
              //   : fadeIn
              //   ? "animate__fadeIn"
              //   : " ")
              //   +
              // " animate_slower animate__infinite"
            }
            component={"img"}
            width={"170px"}
            src={`/${id}.png`}
          ></Box>
          <br />
          <Box
            className={
              "animate__animated "
              //+
              // (!notAnimated
              //   ? "animate__pulse"
              //   : fadeIn
              //   ? "animate__fadeIn"
              //   : " ")
              //   +
              // " animate_slower animate__infinite"
            }
            component={"img"}
            width={"170px"}
            height={"5px"}
            sx={{ mt: "45px", ml: "-3px" }}
            src={"/preloader_small_cropped.gif"}
          ></Box>

          {/* <br />
          <div
            style={{
              width: "170px",
              //height: "200px",
              backgroundSize: "cover",
              verticalAlign: "middle",
              // margin: "20% auto",
            }}
          >
            <div class="line">
              <div class="inner"></div>
            </div>
          </div> */}
          {/* </div> */}
        </Box>
      </Box>
    );
  } else {
    return <></>;
  }
};

const TourBox = (props) => {
  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } =
    useTour();
  useEffect(() => {
    setCurrentStep(props.step);
  }, [props.step]);
  return (
    <Box
      onClick={() => {
        if (isOpen) {
          setCurrentStep(currentStep + 1);
        }
      }}
    >
      {props.children}
    </Box>
  );
};

export const Config = ({
  configuration,
  setConfiguration,
  embedded,
  predef,
}) => {
  // const timer = useAutoLogout(60);
  const modalRef = useRef(null);
  let { id, predefId } = useParams();

  const [scene, setScene] = useState(null);
  const [tools, setTools] = useState([]);
  const [largeMenu, toggleLargeMenu] = useState(false);
  const [currentStepSlick, setCurrentStepSlick] = useState(0);
  const [modelLoaded, toggleModelLoaded] = useState(false);
  const [predefConfig, setPredefConfig] = useState(null);
  const [dataLayerStart, toggleDataLayerStart] = useState(false);
  const innerWidth = useWindowSize();
  const [formSubmitted, toggleFormSubmitted] = useState(false)
  let navigate = useNavigate();
  // const [configuration, setConfiguration] = useState(null)
  const [parametrization, loading, error] = useDocumentDataOnce(
    doc(getFirestore(app), "parametrizations", id).withConverter(converter)
  );
  const [configurationPredef, configurationLoading, configurationError] =
    useDocumentDataOnce(
      predefId
        ? doc(getFirestore(app), "configurations", predefId).withConverter(
            converter
          )
        : null
    );
  useEffect(() => {
    if (configurationPredef && parametrization) {
      console.log("THIS IS CONFIGURAATOIN PREDEF", configurationPredef);
      setConfiguration(
        parametrization["menuConfiguration"].map((i) => {
          return { ...i, ["currentValue"]: configurationPredef.data[i.title], };
        })
      );
      setPredefConfig(
        parametrization["menuConfiguration"].map((i) => {
          return { ...i, ["currentValue"]: configurationPredef.data[i.title] };
        })
      );
      console.log(
        "GOT THIS CONFIGURATION:",
        parametrization["menuConfiguration"].map((i) => {
          return { ...i, ["currentValue"]: configurationPredef.data[i.title] };
        })
      );
    }
  }, [configurationPredef, parametrization]);

  

  const [currentView, setCurrentView] = useState([8, 2, 2, 0, 0, 0, true]);
  const Canvas3dLazy = useMemo(() => lazy(() => import("./Canvas3d")), []);
  useEffect(() => {
    console.log("param:", parametrization);
    parametrization && setConfiguration(parametrization["menuConfiguration"]);
    if (parametrization && parametrization.id == "xhnglL5ZUs6mxoYv3eMI") {
      let bdy = document.getElementsByTagName("body")[0];
      let root = document.getElementById("root");
      bdy.style.setProperty("--joy-fontFamily-body", "'Karla', sans-serif");
      bdy.style.setProperty("font-family", "'Karla', sans-serif");
      root.style.setProperty("--joy-fontFamily-body", "'Karla', sans-serif");
      root.style.setProperty("font-family", "'Karla', sans-serif");
      document.head.insertAdjacentHTML(
        "beforeend",
        `<style>@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&family=Karla:ital,wght@0,200..800;1,200..800&family=Victor+Mono:ital,wght@0,100..700;1,100..700&display=swap')</style>`
      );
    }
    if (parametrization && (parametrization.id == "ALix3WF4dSsrubYu2NBH" || parametrization.id == "nSMcricSRsZydKUphjNV"|| parametrization.id == "3pkpnMYKfM4smxhiJGV2")) {
      let bdy = document.getElementsByTagName("body")[0];
      let root = document.getElementById("root");
      bdy.style.setProperty("--joy-fontFamily-body", "'Poppins', sans-serif");
      bdy.style.setProperty("font-family", "'Poppins', sans-serif");
      root.style.setProperty("--joy-fontFamily-body", "'Poppins', sans-serif");
      root.style.setProperty("font-family", "'Poppins', sans-serif");
      document.head.insertAdjacentHTML(
        "beforeend",
        `<style>@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&family=Karla:ital,wght@0,200..800;1,200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Victor+Mono:ital,wght@0,100..700;1,100..700&display=swap')</style>`
      );
    }
    if (parametrization && (parametrization.id == "rtVTtJ3A3uwX2zITPByc" )) {
      let bdy = document.getElementsByTagName("body")[0];
      let root = document.getElementById("root");
      bdy.style.setProperty("--joy-fontFamily-body", "'Oxanium', sans-serif");
      bdy.style.setProperty("font-family", "'Oxanium', sans-serif");
      root.style.setProperty("--joy-fontFamily-body", "'Oxanium', sans-serif");
      root.style.setProperty("font-family", "'Oxanium', sans-serif");
      document.head.insertAdjacentHTML(
        "beforeend",
        `<style>@import url('https://fonts.googleapis.com/css2?family=Gruppo&family=Lexend:wght@100..900&family=Noto+Serif+Oriya:wght@400..700&family=Oxanium:wght@200..800&family=Poiret+One&display=swap');</style>`
      );
    }
  }, [parametrization]);
  const [step, setStep] = useState(0);

  const tourSteps = [
    {
      // selector: '.very-first-step',
      content: (
        <>
          {
            "Herzlich Willkommen! Bereit für eine kleine Tour durch unseren 3D-Konfigurator?"
          }
          <br />
          <center>
            <Button
              size={"md"}
              sx={{
                "&:hover": {
                  background:
                    parametrization &&
                    parametrization.branding.primaryColor.rgb,
                },
                background:
                  parametrization && parametrization.branding.primaryColor.rgb,
                color: "white",
                margin: "auto",
                mt: 2,
                mx: "auto",
              }}
              onClick={() => setStep(1)}
            >
              Tour starten
            </Button>
          </center>
        </>
      ),
      position: "center",
    },
    {
      selector: ".first-step",
      content: (
        <>
          {"Klicken Sie auf "} <b>hinzufügen</b>
          {", wenn Sie ein Element hinzufügen möchten."}
        </>
      ),
      arrow: false,
      //action: ()=>{setCurrentStep(currentStep+1)}
      // observe: '[class="first-step-parent", class="apps-menu-demo"]',
      // mutationObservables: ['class="first-step"']
    },
    {
      selector: ".Standardtuer",
      content: "Klicken Sie auf ein Element, um dieses auszuwählen.",
      stepInteraction: true,
      //stepInteraction: true,
      // mutationObservables: ['class="first-step"']
    },
    {
      selector: ".second-step",
      content: (
        <>
          {"Klicken Sie auf "}
          <b>ändern</b>
          {", um das entsprechende Element zu ersetzen."}
        </>
      ),
      stepInteraction: true,
    },
    {
      selector: ".Panoramafenster",
      content: "Klicken Sie auf ein Element, um dieses auszuwählen.",
      stepInteraction: true,
    },
    {
      selector: ".remove-element",
      content: (
        <>
          {"Klicken Sie auf "}
          <b>entfernen</b>
          {", um das entsprechende Element zu löschen."}
        </>
      ),
      stepInteraction: true,
    },
    {
      selector: ".slick-next",
      content: (
        <>
          {"Klicken Sie auf den "}
          <b>Rechtspfeil</b>
          {", um zum nächsten Menü zu gelangen."}
        </>
      ),
      stepInteraction: true,
    },
    {
      selector: ".slick-prev",
      content: (
        <>
          {"Klicken Sie auf den "}
          <b>Linkspfeil</b>
          {", um zum vorherigen Menü zu gelangen."}
        </>
      ),
      stepInteraction: true,
    },
    {
      position: "center",
      content: (
        <>
          <center>
            <img
              width={"180px"}
              src={
                "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/Cube_Rotation.gif?alt=media&token=d0a3adb4-338a-404b-bf23-68aafc4e3f76"
              }
            ></img>
          </center>
          <p>
            Tipp: Halten sie die linke Maustaste gedrückt und Bewegen Sie die
            Maus, um den Cube zu drehen.
          </p>
          <center>
            <Button
              size={"md"}
              sx={{
                "&:hover": {
                  background:
                    parametrization &&
                    parametrization.branding.primaryColor.rgb,
                },
                background:
                  parametrization && parametrization.branding.primaryColor.rgb,
                color: "white",
                margin: "auto",
                mt: 2,
                mx: "auto",
              }}
              onClick={() => setStep(9)}
            >
              Alles klar
            </Button>
          </center>
        </>
      ),
    },
    {
      selector: ".share-button",
      content: "Klicken Sie hier, um Ihre Konfiguration mit anderen zu teilen.",
      stepInteraction: false,
    },
    {
      selector: ".submit-button",
      content: "Fertig? Klicken Sie hier, um Ihre Konfiguation abzuschließen.",
      stepInteraction: false,
    },
    // ...
  ];
  // useEffect(()=>{
  //   console.log("timer stuff");
  //   (timer == 0 && modalRef.current?.OpenModal(ModalAnimation.Reveal))
  // },[timer])

  if (configuration && (!predef || predefConfig)) {
    return (
      <>
        {parametrization && parametrization.GTM_ids?.length > 0 && (
          <Helmet>
            {parametrization.GTM_ids.map((id,key) => (
              <script>
                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','` +
                  id +
                  `&l=dataLayer`+key+`');`}
              </script>
            ))}
          </Helmet>
        )}
        {parametrization &&
          !largeMenu &&
          parametrization.globalNotifications &&
          parametrization.globalNotifications
            .filter((i) => i.showOn == "start")
            .map((i) => {
              console.log("go this notification", i);

              return (
                <Box
                  sx={{
                    position: "absolute",
                    width: "100vw",
                    height: 0,
                    zIndex: 100,
                    textAlign: "center",
                  }}
                  element={"div"}
                >
                  <Card
                    sx={{
                      boxShadow: "none",
                      padding: "10px",
                      fontSize: "0.7rem",
                      margin: "15px auto auto auto",
                      width: "350px",
                      background: "#ffcccb",
                      color: "red",
                    }}
                  >
                    {i.text}
                  </Card>
                </Box>
              );
            })}

        <ErrorBoundary>
          {/* <Mask highlightedAreaClassName="highlightedArea"></Mask> */}
          <TourProvider
            highlightedMaskClassName={"highlightedArea"}
            steps={tourSteps}
            styles={{
              maskWrapper: (base) => ({
                ...base,
                pointerEvents: "none",
                zIndex: "9999999999999999",
                color: "white",
                // color: 'white',
                // filter: "blur(4px)",
                opacity: 0.4,
                cursor: "not-allowed",
              }),
              maskArea: (base) => ({ ...base, rx: 5, pointerEvents: "none" }),
              highlightedArea: (base, { x, y, width, height }) => ({
                ...base,
                display: "block",
                stroke: "#f00",
                strokeWidth: 4,
                // width: width -10,
                height: height - 10,
                rx: 4,
                //  x: x - 2.5,
                y: y + 5,
                pointerEvents: "none",
                zIndex: "9999999999999999",
                animation: "fadeIn .7s ease-in-out",
                // cursor: "pointer",
                // backgroundColor: "rgba(255, 0 , 0, 0.5)"
                // x: x + 10,
                // y: y + 10,
                // border: "1px dashed grey",
                // borderRadius: "8px"
              }),

              popover: (base) => ({
                ...base,
                borderRadius: "8px",
                zIndex: "9999999999999999999",
                // padding: "15px"
                // backgroundColor: "rgba(255,255,255,0.2)"
                // color: 'white',
                // filter: "blur(4px)",
                // opacity: 0.8
              }),
              dot: (base) => ({
                ...base,
                display: "none",
                background: parametrization.branding.primaryColor.rgb,
                // color: 'white',
                // filter: "blur(4px)",
                // opacity: 0.8
              }),

              badge: (base) => ({
                ...base,
                display: "none",
                background: parametrization.branding.primaryColor.rgb,
              }),
            }}
            showNavigation={false}
            disableInteraction={true}
            disableDotsNavigation
            onClickMask={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            // onClickHighlighted={(e, clickProps) => {
            //   console.log("No interaction at all");
            //   if (clickProps.currentStep < tourSteps.length) {
            //     // e.stopPropagation();
            //     // e.preventDefault();
            //     clickProps.setCurrentStep(clickProps.currentStep + 1);
            //   }
            // }}
          >
            <ThemeProvider theme={THEME}>
              <MaterialCssVarsProvider theme={THEME}>
                {/* {!(window.location.href.indexOf("embed") > -1) && (
                  <Box
                    onClick={() => {
                      parametrization &&
                        parametrization.branding.urlToWebsite &&
                        window.open(
                          parametrization.branding.urlToWebsite,
                          "_blank"
                        );
                    }}
                    component={"img"}
                    id={"brand-icon"}
                    src={parametrization && parametrization.branding.brandIcon}
                    width={innerWidth > 800 ? "120px" : "60px"}
                    sx={{
                      maxHeight: "70px",
                      maxWidth: "95px",
                      objectFit: "contain",
                      zIndex: 100,
                      position: "fixed",
                      top: "40px",
                      left: "20px",
                      cursor:
                        parametrization && parametrization.branding.urlToWebsite
                          ? "pointer"
                          : "auto",
                    }}
                  ></Box>
                )} */}
                {/* <Box
                sx={{
                  color: "#888",
                  width: innerWidth > 800 ? "95px" : "60px",
                  textAlign: "center",
                  zIndex: 100,
                  position: "fixed",
                  fontSize: innerWidth > 800 ? "1rem" : "0.6rem",
                  top: innerWidth > 800 ? "110px" : "75px",
                  left: "20px",
                }}
              >
                {parametrization && parametrization.productName}
              </Box> */}
                <JoyCssVarsProvider>
                  {/* <ConfigLoadingOverlay showOverlayProp={!modelLoaded} /> */}
                  <Box
                    sx={{
                      position: "fixed",
                      top: innerWidth > 800 ? "10px" : "7px",
                      right: innerWidth > 800 ? "20px" : "20px",
                      zIndex: 2000,
                    }}
                  >
                    {parametrization && parametrization.multilanguage && (
                      <Typography
                        variant="overline"
                        sx={{ fontSize: "0.7rem" }}
                      >
                        {" "}
                        <Box
                          element={"span"}
                          sx={{
                            display: "inline",
                            cursor: "pointer",
                            "&:hover": { textDecoration: "underline" },
                          }}
                        >
                          EN{" "}
                        </Box>{" "}
                        |{" "}
                        <span style={{ fontWeight: "bold", display: "inline" }}>
                          DE
                        </span>{" "}
                      </Typography>
                    )}
                  </Box>
                  <TourBox step={step}>
                    <Box
                      id={"sense_start_event"}
                      onDragStart={() => {
                        parametrization.GTM_ids && parametrization.GTM_ids.map((id, key) => {
                          let dataLayer = window["dataLayer"+key] || [];
                          // console.log("pushing to", dataLayer);
                          !dataLayerStart &&
                            dataLayer.push({
                              event: "yn_start",
                              configurator: parametrization.id,
                            });
                        })
                        
                        toggleDataLayerStart(true);
                      }}
                      onClick={() => {
                        parametrization.GTM_ids && parametrization.GTM_ids.map((id, key) => {
                          let dataLayer = window["dataLayer"+key] || [];
                          // console.log("pushing to", dataLayer);
                          !dataLayerStart &&
                            dataLayer.push({
                              event: "yn_start",
                              configurator: parametrization.id,
                            });
                        })
                        toggleDataLayerStart(true);
                      }}
                    >
                      <Suspense fallback={<>STUFFF!</>}>
                        <Canvas3dLazy
                          parametrizationId={id}
                          largeMenu={largeMenu}
                          setConfiguration={setConfiguration}
                          configuration={predef ? predefConfig : configuration}
                          currentView={currentView}
                          currentStep={currentStepSlick}
                          setCurrentStep={setCurrentStepSlick}
                          parametrization={parametrization}
                          toggleModelLoaded={toggleModelLoaded}
                          modelLoaded={modelLoaded}
                          setScene={setScene}
                          setTools={setTools}
                        />
                      </Suspense>
                      <div style={{position: "fixed", userSelect: "none", right: "20px", bottom: "230px"}}>
                      <img src={"/3d_activated.png"} width={innerWidth < 800 ? "55px": "70px"} ></img>
                      </div>
                      
                      <MenuSlider
                        embedded
                        formSubmitted={formSubmitted}
                        setConfiguration={setConfiguration}
                        configuration={predef ? predefConfig : configuration}
                        menuConfiguration={menuConfiguration}
                        setCurrentView={setCurrentView}
                        toggleModelLoaded={toggleModelLoaded}
                        modelLoaded={modelLoaded}
                        parametrization={
                          parametrization.technicalMenuOrder
                            ? {
                                ...parametrization,
                                ["menuConfiguration"]:
                                  parametrization.technicalMenuOrder.map(
                                    (title) => {
                                      return {
                                        ...parametrization[
                                          "menuConfiguration"
                                        ].filter((i) => i.title == title)[0],
                                      };
                                    }
                                  ),
                              }
                            : parametrization
                        }
                        toggleLargeMenu={toggleLargeMenu}
                        currentStep={currentStepSlick}
                        setCurrentStep={setCurrentStepSlick}
                        scene={scene}
                        tools={tools}
                      ></MenuSlider>
                    </Box>
                  </TourBox>
                </JoyCssVarsProvider>
              </MaterialCssVarsProvider>
            </ThemeProvider>
          </TourProvider>
        </ErrorBoundary>
      </>
    );
  } else if (loading) {
    return (
      <>
        <Helmet>
          <title>Lade 3D-Erlebnis...</title>
        </Helmet>{" "}
        <ConfigLoadingOverlay showOverlayProp={true} fadeIn />
      </>
    );
  } else if (error) {
    navigate("/404");
  } else {
    return <ConfigLoadingOverlay showOverlayProp={true} />;
  }
};

export default Config;
